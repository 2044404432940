<template>
  <div class="wrapper">
    <b-container fluid>
      <div style="height: 100vh;width:100vw; background-color: black;" v-if="isMobile"></div>
      <video autoplay muted loop id="myVideo" v-if="!isMobile">
        <source
          src="https://codelos-public-media.s3.eu-central-1.amazonaws.com/assets/videos/intro-video.mov"
          type="video/mp4"
        />
        Your browser does not support HTML5 video.
      </video>
      <div class="black-background-for-mobile"></div>
      <div class="container black overlay">
        <b-navbar
          toggleable="lg"
          v-bind:type="'dark'"
          data-aos="fade-down"
          class="px-3"
        >
        <b-navbar-brand href="/">
            <img class="pb-3" src="../assets/logo.svg" />
            <span class="pt-3"> metafer</span>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item href="/" right>Home</b-nav-item>
              <b-nav-item href="/how-it-works" right>How it works</b-nav-item>
              <b-nav-item href="/about" right>About us</b-nav-item>
              <b-nav-item href="/apply" right>Apply to next event</b-nav-item>
              <b-nav-item href="mailto:info@metafer.io" right>Get in touch</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <div class="header">
          <div class="row" style="height: 100%">
            <div
              class="col-md-12 col-xl-8 col-lg-12 d-flex py-5 px-4 align-items-center"
              data-aos="fade-up"
            >
              <div>
                <h1>
                  <span style="color: #9bffa5">CLEANTECH</span> CONNECT
                </h1>
                <p v-bind:class="{ active: loaded == true }" class="mt-5">
                  CLEANTECH CONNECT is a virtual reality exhibition where visionary green tech startups and industry leaders converge in an immersive 3D experience. Team members have the flexibility to join from anywhere, using only a web browser.<br>Upcoming fairs:
 17.11.23 / 15.12.23 / 19.01.24

                </p>
                  <a variant="primary" class="apply-button btn-primary btn " href="/apply">Apply to next event</a
                  >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container class="mt-5 mb-5">
      <div class="section">
        <div class="container text-dark mt-0">
          <div class="row feature" id="sales-section">
            <div class="col-md-7" align="left" data-aos="fade-right">
              <img
                alt="Lastgang analysieren überprüfen und korrigieren"
                class="icon icon-lg"
                src="../assets/woman-presenting.png"
              />
            </div>
            <div
              class="col description-right justify-content-center align-self-center"
            >
              <div>
                <h2>Boost your sales</h2>
                <p>
                  Trade fairs are one of the most effective sales channels, generating an average of 5-6 leads per hour. This means a company can acquire up to 48 qualified leads in 8 hours. At CLEANTECH CONNECT, we provide a unique platform to showcase your product to your ideal leads, ensuring that every interaction counts.
                </p>
                <a class="mt-3 mb-5 btn" href="/apply">Get Started</a>
              </div>
            </div>
          </div>
          <div class="row feature" id="data-section">
            <div
              v-if="isMobile"
              class="col-md-7"
              align="left"
              data-aos="fade-right"
            >
              <img
                alt="Lastgang analysieren überprüfen und korrigieren"
                class="icon icon-lg"
                src="../assets/from-home.png"
              />
            </div>
            <div
              class="col description-left justify-content-center align-self-center"
            >
              <div>
                <h2>Harness the benefits of VR</h2>
                <p>
                  Say hello to a virtual world that allows you to showcase your
                  products, connect with customers, and generate leads from the
                  comfort of your own office. The average lead cost for B2B
                  companies is around $200. By utilizing VR technology, you can
                  reduce expenses by a factor of 10 and maximize your sales ROI
                  by at least 10x.
                </p>
                <a class="mt-3 mb-5 btn" href="/apply">Get Started</a>
              </div>
            </div>
            <div
              v-if="!isMobile"
              class="col-md-7"
              align="right"
              data-aos="fade-left"
            >
              <img
                alt="Lastgang analysieren überprüfen und korrigieren"
                class="icon icon-lg"
                src="../assets/from-home.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container" data-aos="fade-up" id="developers-section">
          <div class="row feature">
            <div class="col-md-7" align="left" data-aos="fade-right">
              <img
                alt="Lastgang analysieren überprüfen und korrigieren"
                class="icon icon-lg"
                src="../assets/face-to-face.png"
              />
            </div>
            <div
              class="col description-right justify-content-center align-self-center"
            >
              <h2>Face-to-face with Decision-Makers</h2>
              <p>
                Our Matchmaking AI utilizes advanced algorithms to eliminate networking barriers, ensuring that you reach your dream customers with precision. CLEANTECH CONNECT provides you with unique opportunities to engage face-to-face with influential decision makers from the top of your industry, propelling your business to new heights of success.
              </p>
              <a class="mt-3 mb-5 btn" href="/apply">Get Started</a>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container class="black py-5 px-lg-5" style="background-color: black" fluid>
      <div class="container">
        <div class="banner">
          <div class="row">
            <div
              class="col-md-12 col-xl-12 d-flex py-5 px-4 align-items-center"
              data-aos="fade-up"
            >
              <div>
                <h1>
                  Secure one of only 25 exhibitor spots and connect with your
                  ideal leads.
                </h1>
                <a href="/apply" ref="apply-button" variant="primary"  class="apply-button btn-primary btn  mt-4" 
                  >Apply to next event</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container fluid class="light-gray-blue">
      <div class="container">
        <div class="footer">
          © Metafer
          <br /><br />
          Made with ❤️ in Munich, Porto and Ko Samui.
          <br /><br />
          <ul class="inline-list">
            <li>
              <u><a href="/impressum">Imprint</a></u>
            </li>
            <li>
              <u><a href="/privacy">Data privacy</a></u>
            </li>
            <!--<li>
              <a
                href="https://www.facebook.com/Codelosio-GmbH-103666008172517"
                target="blank"
                class="mr3"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/codelos"
                target="blank"
                class="mr3"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.xing.com/pages/codelos-iogmbh"
                target="blank"
                class="mr3"
                ><i class="fab fa-xing"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/codelos.io/"
                target="blank"
                class="mr3"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>-->
          </ul>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      email:"",
      loaded: false,
      navbarType: "dark",
      applyButtonCaption: "Apply to next event"
    };
  },
  methods: {
    changeNavbarType() {
      this.navbarType == (this.navbarType == "light" ? "dark" : "light");
    },
    sendEmail(){
      if( /^[^@]+@\w+(\.\w+)+\w$/.test(this.email)){
        this.applyButtonCaption = "Application send ✅"
      }else{
        this.validEmail = false;
      }
    }
  },
  mounted() {
    setTimeout(() => (this.loaded = true), 9000);
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  height: 100%;
}

/** Navbar **/
.navbar-brand img {
  height: 70px;
}

.navbar-light {
  font-size: 20px;
  font-weight: 500;
  background: black;
}

.nav-link.dropdown-toggle {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #000;
}

::v-deep .dropdown-menu {
  background: #ffff;
  margin-top: 18px;
}

::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.28rem 2rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

::v-deep .dropdown-toggle {
  outline: none;
}

/** Container **/
.container {
  height: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.header {
  text-align: left;
  height: 80%;
  vertical-align: middle;
}

.header h1 {
  margin: 0px;
  font-size: 91px;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -0.01em;
  text-transform: none;
  font-family: Aeonik, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.header p {
  margin-bottom: 5vh;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.01em;
  font-family: Pretendard, sans-serif;
}

.banner {
  text-align: center;
  margin-left: 0px;
  width: 100%;
}

.banner h1 {
  font-size: 65px;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -0.01em;
  text-transform: none;
  font-family: Aeonik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin-bottom: 25px;
}

.lead {
  padding-top: 60px;
  padding-bottom: 60px;
}

.btn-primary:hover {
  background-color: #d4d1d1;
}

video {
  border-radius: 30px;
}

.demos {
  margin-top: 25px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.preview-element {
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 31%);
  transition-property: border-color;
  transition-duration: 350ms;
}

.video-wrapper {
  padding: 0;
  margin-top: 6%;
  margin-bottom: 6%;
}

.orange {
  background-color: #ff7745;
}

.blue {
  background-color: #3b85f7;
}

.mauve {
  background-color: #944ff6;
}

.green {
  background-color: #6eecd5;
}

.gray {
  background-color: #cdcdcd;
}

.light-gray-blue-gradient {
  background: linear-gradient(-45deg, #fff2f5, #f7f5ff, #edfbff);
  background: #f2f8ff;
}

.light-gray-blue {
  background: #f3f4f6;
}

.black {
  background: black;
  background: none;
  color: white;
}

.black .btn-primary {
  background-color: white;
  color: black;
}

@media (max-width: 500px) {
  .black .btn-primary,
  .btn-primary {
    padding-left: 35px;
    font-size: 22px;
    padding-right: 35px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.black .btn-primary:hover {
  background-color: #ffffffd9;
  transition: 0.4s;
}

.light-gray {
  background-color: #f8f9fc;
}

.preview-element .title {
  text-align: center;
  padding: 19px;
  font-size: 19px;
  font-weight: 500;
  background: #ffff;
}

/* footer */
.footer {
  padding-top: 110px;
  text-align: left;
  padding-bottom: 50px;
  padding-left: 0;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer ol,
ul {
  padding-left: 0px;
}

.inline-list {
  list-style: unset;
}

.inline-list li {
  display: inline-block;
  margin-right: 15px;
}

.inline-list li a:hover {
  text-decoration: underline;
}
/* end footer */

.dropdown-menu {
  border-radius: 0px;
}

.highlight {
  background-image: linear-gradient(
    to right,
    rgb(255 231 133),
    rgb(255 231 133)
  );
  background-size: 0% 1.12em;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: background-size 2000ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.active .highlight {
  background-size: 100% 1.12em;
}

.drag-n-drop-anim {
  animation: drag;
  animation-duration: 3s;
  position: relative;
  display: block;
}

.change-anim-list {
  overflow: hidden;
  height: 70px;
}

.change-anim-item {
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  position: relative;
  display: block;
}

.fa-mouse-pointer {
  font-size: 38px;
  color: black;
  margin-top: -22px;
  display: block;
  margin-left: 49px;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffff;
  height: 22px;
}

.apply-button {
  border-radius: 35px;
  height: 75px;
}

@keyframes change {
  0%,
  12.66%,
  100% {
    top: 0px;
  }
  16.66%,
  29.32% {
    top: -85px;
  }
  33.32%,
  45.98% {
    top: -170px;
  }
  49.98%,
  62.64% {
    top: -255px;
  }
  66.64%,
  79.3% {
    top: -340px;
  }
  83.3%,
  95.96% {
    top: -425px;
  }
}

@keyframes drag {
  0% {
    left: -600px;
    top: 900px;
  }
  30% {
    left: -600px;
    top: 900px;
  }
  60% {
    left: 60px;
    top: 20px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes zoom-in {
  0% {
    width: 0%;
    height: 0px;
  }
  75% {
    width: 30%;
    height: 50px;
  }
  100% {
    width: 100%;
    height: 148px;
  }
}

@keyframes show-menu {
  0% {
    opacity: 0;
    margin-left: -20px;
    width: 60%;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

input {
  height: 75px;
  border-radius: 8px 0px 0px 8px;
  min-width: 300px;
  border: 1px solid #9bffa6;
  padding: 0px;
  padding-left: 32px;
  position: relative;
  top: 2px;
  background-color: #00000069;
  color: white;
  font-size: 20px;
}
.apply-button {
  border-radius: 35px;
  height: 75px;
  min-width: 300px;
}

@media screen and (max-width: 479px) {
  .header h1 {
    font-size: 50px;
  }
  .header p {
    font-size: 14px;
    padding-bottom: 25px;
  }
  .preview-element {
    margin-top: 45px;
  }
  .banner {
    margin-left: -25px;
    width: 100%;
  }
  .banner h1 {
    font-size: 35px;
    line-height: 1;
    font-weight: 800;
    letter-spacing: -0.01em;
    text-transform: none;
    font-family: Aeonik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    margin-bottom: 25px;
  }
  input {
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 25px;
    min-width: 100%;
  }
  .apply-button {
    border-radius: 35px;
    height: 75px;
    width: 100%;
  }
  .header {
    margin-left: -25px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container-fluid .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }

  ::v-deep .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: fit-content;
    left: auto;
    right: auto;
    -webkit-animation: show-menu-data-v-8dc7cce2;
    animation: show-menu-data-v-8dc7cce2;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    border-radius: 7px;
    box-shadow: rgb(51 51 51 / 50%) 0px 20px 40px -30px;
    border: solid 0.6px #eeecf2;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f0f0f0;
    color: white;
    font-weight: 900;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-content {
    margin: 100px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu h2 {
    font-size: 17px;
    font-weight: 700;
    padding: 0rem 0rem 1em 2rem;
    border-bottom: solid 1px #cdcdcd;
    margin-bottom: 1rem;
  }

  ::v-deep .dropdown-item {
    font-size: 16px;
  }

  .container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

/*@media (min-width: 1200px) {

}*/

::v-deep .dropdown-menu {
  padding: 27px 0 26px;
}

.container.gray {
  background-color: #f7f7f9;
}

.header img {
  box-shadow: 0 1px 10px rgb(0 0 0 / 7%), 0 2.4px 5.7px rgb(0 0 0 / 2%),
    0 4px 11.3px rgb(0 0 0 / 2%), 0 10.6px 17.5px rgb(0 0 0 / 2%),
    0 14px 25.7px rgb(0 0 0 / 3%), 0 35.5px 39.6px rgb(0 0 0 / 4%),
    0 40px 48px rgb(0 0 0 / 3%);
  border-radius: 9px;
}

.screens-wrapper {
  width: 100%;
}

.screen-1 {
  margin-top: 36%;
  left: 5%;
  z-index: 1;
  position: relative;
  float: left;
  width: 39%;
}

.screen-2 {
  left: 0px;
  margin-top: 0px;
  position: relative;
  float: right;
  width: 61%;
}

.row.row.feature {
  min-height: 400px;
}

.row.feature img {
  width: 100%;
  border-radius: 22px;
  margin-top: 6%;
  margin-bottom: 6%;
}

.row.feature .btn {
  text-decoration: none;
  color: black;
  border: 2px solid black;
  border-radius: 0.375em;
  cursor: pointer;
  display: inline-block;
  font-family: proxima-nova, "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  line-height: 23px;
  padding: 0.5rem 1.5rem;
  white-space: pre;
  font-size: 0.9375rem;
  opacity: 1;
  transition: transform 0.2s linear 0s, background-color 0.2s linear 0s;
  transform: translateY(0px) scale(1.001);
}

.container-fluid {
  width: 100%;
  margin: 0;
  padding: 0;
}

.container-fluid .container.overlay {
  position: absolute;
  top: 0;
  background-color: #0000009e;
  height: 105vh;
  width: 100%;
  max-width: unset;
}

h3 {
  font-size: calc(0.7rem + 0.6vw);
}

h2 {
  font-family: Avenir Next, Avenir Next W01, Avenir, helvetica, arial;
  font-size: 1.77777778rem;
  line-height: 2.66666667rem;
  margin-bottom: 0.5em;
  font-weight: 700;
}

@media (min-width: 1200px) {
  h2 {
    font-size: 2.3rem;
  }
}

.description-right {
  padding-left: 5%;
}

.description-left {
  padding-right: 5%;
}

.ml-auto {
  margin-left: auto !important;
}

.navbar-dark .navbar-brand {
  color: #e4e1e1;
  font-weight: 600;
}

::v-deep .navbar-dark .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
  font-weight: 700 !important;
}

.navbar {
  background: black;
  background: none;
}

ul {
  list-style-type: none;
  list-style: none;
}

.btn-primary {
  font-family: Pretendard, sans-serif;
  font-size: 26px;
  line-height: 20px;
  padding: 27px 57px;
  transition: background-color 200ms ease, color 200ms ease;
  border: none;
  font-weight: 600;
}

#myVideo {
  top: 0;
  right: 0;
  bottom: 0;
  height: 105vh;
  border-radius: 0;
  z-index: -1;
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #c7c7c8;
}

input:hover::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ffff;
}

.navbar-light .navbar-brand{
  color: white;
}
</style>
