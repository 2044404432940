<template>
  <div class="wrapper">
    <b-container fluid>
      <div class="container">
        <b-navbar
          toggleable="lg"
          v-bind:type="'light'"
          data-aos="fade-down"
          
        >
        <b-navbar-brand href="/">
            <img class="pb-3" src="../assets/logo-black.svg" />
            <span class="pt-3" style="color: black;"> metafer</span>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item href="/" right>Home</b-nav-item>
              <b-nav-item href="/how-it-works" right>How it works</b-nav-item>
              <b-nav-item href="/about" right>About us</b-nav-item>
              <b-nav-item href="/apply" right>Apply to next event</b-nav-item>
              <b-nav-item href="mailto:info@metafer.io" right>Get in touch</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <div class="header text-center my-5">
          <div class="row">
            <div
              class="
                col-md-12 col-xl-12
                d-flex
                py-5
                mt-5
                align-items-center
                aos-init aos-animate
              "
              data-aos="fade-up"
            >
              <div>
                <h1>Hi there 👋</h1>
                <p>
                  We are a team of passionate world improvers on a mission to bring attention to the most impactful ideas. Our belief in the power of connection drives us to bring people together. We recognize that the transition to a sustainable, better world relies on nurturing brilliant ideas and accelerating their journey into reality. At Metafer, we are dedicated to fostering innovation and making a tangible difference in shaping a brighter future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container>
      <div class="section">
        <div class="container text-dark mt-0" data-aos="fade-up">
          <div class="row">
            <div
              class="
                col-md-12 col-xl-12
                d-flex
                py-5
                align-items-center
                text-center
              "
            >
              <h1 class="text-center">Our team</h1>
            </div>
          </div>
          <div class="row team">
            <div class="col-md description-leftight">
              <img
                alt="Alessa Schuhmacher"
                class="icon icon-s px-3"
                src="../assets/alessa-round.png"
              />
              <h2>
                Alessa Schuhmacher<br>
                Co-Founder
              </h2>
              <p>
                Alessa loves trade shows and attends them regularly. Years of training helped her to crack the code of an optimal trade show experience. When she is not at trade fairs, she devotes her energy to making sustainability a tangible reality at Studio 177.</p>
              <p></p>
            </div>
            <div
              class="
                col-md
                description-leftight
              "
            >
              <img
                alt="Louq Gharnati"
                class="icon icon-s px-3"
                src="../assets/louq-round.png"
              />
              <h2>
                Louq<br>Gharnati<br />
                  Co-Founder
              </h2>
              <p>
                Louq has been empowering energy companies towards a sustainable, efficient, and digitalized future since 2009. As the founder of Codelos.io, he is a passionate advocate for efficient, clean, and impactful technology.
              </p>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Stefan Hunziker"
                class="icon icon-s px-3"
                src="../assets/stefan-round.png"
              />
              <h2>
                {{ $t("about.faces.face3.name") }}<br />Co-Founder
              </h2>
              <p>
                Stefan is "The Hacker" and our secret weapon. He is the CTO of Codelos.io, writes four programming languages at a native level, and has been keeping critical business processes for energy supply companies running like a swiss clock for several years.
              </p>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Youness Attoumi co founder metafer"
                class="icon icon-s px-3"
                src="../assets/youness-round.png"
              />
              <h2>
                Youness Attoumi<br />Co-Founder
              </h2>
              <p>
                Youness has designed exhibition booths for top brands, such as Audi. Now, he is not only focusing on making Metafer look and feel real, but also on breaking the boundaries and physical limitations of conventional exhibition design.
              </p>
              <p></p>
            </div>
            <!--
            <div class="col-md description-leftight">
              <img
                alt=""
                class="icon icon-s"
                src="../assets/anna-round.png"
              />
              <h3>Dackel-Fanatiker</h3>
              <h2>Anna Fogel<br />Chief Evangelist</h2>
              <p>
                Wenn jemand weiß, was virales Potenzial hat, dann Anna. Sie
                ist Social-media-coach, hat mehrere nachhaltige Brands von der
                Einführung am Markt bis zum Erfolg begleitet. Ihre geheime Super-Power: Ihre Python-Skills.
              </p>
              <p></p>
            </div>
            -->
          </div>
        </div>
      </div>
    </b-container>
    <b-container>
      <div class="section">
        <div class="container text-dark mt-0">
          <div class="row">
            <div
              class="
                col-md-12 col-xl-12
                d-flex
                mt-5
                py-5
                align-items-center
                text-center
              "
            >
              <h1 class="text-center">Our true colors</h1>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container>
      <div class="section">
        <div class="container text-dark mt-0 gray">
          <div class="row values justify-content-center align-self-center">
            <div class="col-md description-leftight">
              <h3>{{ $t("about.ourValues.values.value1.title") }}</h3>
              <h2>{{ $t("about.ourValues.values.value1.subTitle") }}</h2>
              <p>
                {{ $t("about.ourValues.values.value1.description") }}
              </p>
              <div class="row" data-aos="fade-right">
                <img
                  alt=""
                  class="icon icon-s"
                  src="../assets/running.svg"
                  align="left"
                />
              </div>
            </div>
            <div class="col-md description-leftight">
              <h3>{{ $t("about.ourValues.values.value2.title") }}</h3>
              <h2>{{ $t("about.ourValues.values.value2.subTitle") }}</h2>
              <p>
                {{ $t("about.ourValues.values.value2.description") }}
              </p>
              <div class="row" data-aos="fade-right">
                <img
                  alt=""
                  class="icon icon-s"
                  src="../assets/aquarium.svg"
                  align="left"
                />
              </div>
            </div>
          </div>
          <div class="row values" id="aotomators">
            <div
              class="
                col-md
                description-leftight
                justify-content-center
                align-self-center
              "
            >
              <h3>{{ $t("about.ourValues.values.value3.title") }}</h3>
              <h2>{{ $t("about.ourValues.values.value3.subTitle") }}</h2>
              <p>
                {{ $t("about.ourValues.values.value3.description") }}
              </p>
              <div class="row" data-aos="fade-right">
                <img
                  alt=""
                  class="icon icon-s"
                  src="../assets/fire.svg"
                />
              </div>
            </div>
            <div class="col-md description-leftight">
              <h3>{{ $t("about.ourValues.values.value4.title") }}</h3>
              <h2>{{ $t("about.ourValues.values.value4.subTitle") }}</h2>
              <p>
                {{ $t("about.ourValues.values.value4.description") }}
              </p>
              <div class="row" data-aos="fade-right">
                <img
                  alt=""
                  class="icon icon-s"
                  src="../assets/fun.svg"
                />
              </div>
            </div>
          </div>
          <div class="row values">
            <div class="col-md" align="right" data-aos="fade-left"></div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container>
      <div class="footer">
        {{ $t("footer.title") }}
        <br /><br />
        {{ $t("footer.subTitle") }}
        <br /><br />
        <ul class="inline-list">
          <li>
            <u
              ><a href="/impressum"> {{ $t("footer.ImpressumButton") }}</a></u
            >
          </li>
          <li>
            <u
              ><a href="/privacy">
                {{ $t("footer.dataProtectionButton") }}</a
              ></u
            >
          </li>
          
        </ul>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      loaded: false,
      navbarType: "light",
    };
  },
  methods: {
    changeNavbarType() {
      this.navbarType == (this.navbarType == "light" ? "dark" : "light");
    },
  },
  mounted() {
    setTimeout(() => (this.loaded = true), 9000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  height: 100%;
}

/** Navbar **/
/** Navbar **/
.navbar-brand img {
  height: 70px;
}

.navbar-light {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.nav-link.dropdown-toggle {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #000;
}

::v-deep ul.dropdown-menu {
  background: #ffff;
  margin-top: 18px;
  list-style-type: none !important;
}

::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.28rem 2rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

::v-deep .dropdown-toggle {
  outline: none;
}

::v-deep .dropdown-menu {
  background: #ffff;
  margin-top: 18px;
}

::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.28rem 2rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

::v-deep .dropdown-toggle {
  outline: none;
}

/** Container **/
.container {
  height: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

@media screen and (max-width: 479px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.header {
  text-align: left;
}

.header h1 {
  margin: 0px;
  font-size: 91px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.035em;
  text-transform: none;
  margin-bottom: 25px;
}

.header p {
  margin-bottom: 5vh;
  font-size: calc(0.7rem + 0.6vw);
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: -0.01em;
  font-family: Pretendard, sans-serif;
}

.lead {
  padding-top: 60px;
  padding-bottom: 60px;
}

.btn-primary:hover {
  background-color: #d4d1d1;
}

video {
  width: 100%;
  border-radius: 30px;
}

.demos {
  margin-top: 25px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.preview-element {
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 31%);
  transition-property: border-color;
  transition-duration: 350ms;
}

.video-wrapper {
  padding: 0;
  margin-top: 6%;
  margin-bottom: 6%;
}

.orange {
  background-color: #ff7745;
}

.blue {
  background-color: #3b85f7;
}

.mauve {
  background-color: #944ff6;
}

.green {
  background-color: #6eecd5;
}

.gray {
  background-color: #cdcdcd;
}

.light-gray-blue {
  background: linear-gradient(-45deg, #fff2f5, #f7f5ff, #edfbff);
  background: #f2f8ff;
}

.black {
  background: black;
  color: white;
}

.black .btn-primary {
  background-color: white;
  color: black;
}

.light-gray {
  background-color: #f8f9fc;
}

.preview-element .title {
  text-align: center;
  padding: 19px;
  font-size: 19px;
  font-weight: 500;
  background: #ffff;
}

/* footer */
.footer {
  padding-top: 110px;
  text-align: left;
  padding-bottom: 50px;
  padding-left: 0;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer ol,
ul {
  padding-left: 0px;
}

.inline-list {
  list-style: unset;
}

.inline-list li {
  display: inline-block;
  margin-right: 15px;
}

.inline-list li a:hover {
  text-decoration: underline;
}
/* end footer */

a.dropdown-item {
  background-color: #212529;
}

.dropdown-menu {
  border-radius: 0px;
}

.highlight {
  background-image: linear-gradient(
    to right,
    rgb(255 231 133),
    rgb(255 231 133)
  );
  background-size: 0% 1.12em;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: background-size 2000ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.active .highlight {
  background-size: 100% 1.12em;
}

.drag-n-drop-anim {
  animation: drag;
  animation-duration: 3s;
  position: relative;
  display: block;
}

.change-anim-list {
  overflow: hidden;
  height: 70px;
}

.change-anim-item {
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  position: relative;
  display: block;
}

.fa-mouse-pointer {
  font-size: 38px;
  color: black;
  margin-top: -22px;
  display: block;
  margin-left: 49px;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffff;
  height: 22px;
}

@keyframes change {
  0%,
  12.66%,
  100% {
    top: 0px;
  }
  16.66%,
  29.32% {
    top: -85px;
  }
  33.32%,
  45.98% {
    top: -170px;
  }
  49.98%,
  62.64% {
    top: -255px;
  }
  66.64%,
  79.3% {
    top: -340px;
  }
  83.3%,
  95.96% {
    top: -425px;
  }
}

@keyframes drag {
  0% {
    left: -600px;
    top: 900px;
  }
  30% {
    left: -600px;
    top: 900px;
  }
  60% {
    left: 60px;
    top: 20px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes zoom-in {
  0% {
    width: 0%;
    height: 0px;
  }
  75% {
    width: 30%;
    height: 50px;
  }
  100% {
    width: 100%;
    height: 148px;
  }
}

@keyframes show-menu {
  0% {
    opacity: 0;
    margin-left: -20px;
    width: 60%;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@media screen and (max-width: 479px) {
  .header h1 {
    font-size: 50px;
  }
  .header p {
    padding-bottom: 25px;
  }
  .preview-element {
    margin-top: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }

  ::v-deep .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: fit-content;
    left: auto;
    right: auto;
    -webkit-animation: show-menu-data-v-8dc7cce2;
    animation: show-menu-data-v-8dc7cce2;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    border-radius: 7px;
    box-shadow: rgb(51 51 51 / 50%) 0px 20px 40px -30px;
    border: solid 0.6px #eeecf2;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f0f0f0;
    color: white;
    font-weight: 900;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-content {
    margin: 100px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu h2 {
    font-size: 17px;
    font-weight: 700;
    padding: 0rem 0rem 1em 2rem;
    border-bottom: solid 1px #cdcdcd;
    margin-bottom: 1rem;
  }

  ::v-deep .dropdown-item {
    font-size: 16px;
  }
}

/*@media (min-width: 1200px) {

}*/

::v-deep .dropdown-menu {
  padding: 27px 0 26px;
}

.container.gray {
  background-color: #f7f7f9;
  border-radius: 22px;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 700px) {
  .container.gray {
    padding: 90px;
  }
}

.header img {
  box-shadow: 0 1px 10px rgb(0 0 0 / 7%), 0 2.4px 5.7px rgb(0 0 0 / 2%),
    0 4px 11.3px rgb(0 0 0 / 2%), 0 10.6px 17.5px rgb(0 0 0 / 2%),
    0 14px 25.7px rgb(0 0 0 / 3%), 0 35.5px 39.6px rgb(0 0 0 / 4%),
    0 40px 48px rgb(0 0 0 / 3%);
  border-radius: 9px;
}

.screens-wrapper {
  width: 100%;
}

.screen-1 {
  margin-top: 36%;
  left: 5%;
  z-index: 1;
  position: relative;
  float: left;
  width: 39%;
}

.screen-2 {
  left: 0px;
  margin-top: 0px;
  position: relative;
  float: right;
  width: 61%;
}

.row.team img {
  width: 100%;
  border-radius: 22px;
  margin-top: 6%;
  margin-bottom: 6%;
}

.row.team .col {
  width: 100px;
}

.row.values img {
  width: unset;
  max-height: 200px;
  margin-right: 0;
  margin-left: auto;
}

.container {
  max-width: 1443px;
}

.container-fluid {
  width: 100%;
}

@media screen and (min-width: 479px) {
  .container-fluid .container {
    padding-right: 60px;
    padding-left: 60px;
  }
}

h3 {
  font-size: calc(0.7rem + 0.6vw);
  width: 100%;
}

h2 {
  font-family: Avenir Next, Avenir Next W01, Avenir, helvetica, arial;
  font-size: 1.77777778rem;
  line-height: 2.66666667rem;
  margin-bottom: 0.5em;
  font-weight: 700;
  width: 100%;
}

h1 {
  font-family: Avenir Next, Avenir Next W01, Avenir, helvetica, arial;
  font-size: 3rem;
  line-height: 2.66666667rem;
  margin-bottom: 0.5em;
  font-weight: 700;
  width: 100%;
}

@media (min-width: 1200px) {
  h2 {
    font-size: 2.3rem;
  }
}

.description-right {
  padding-left: 5%;
}

.description-left {
  padding-right: 5%;
}

.ml-auto {
  margin-left: auto !important;
}

.navbar-dark .navbar-brand {
  color: #e4e1e1;
  font-size: 27px;
  font-weight: 500;
}

::v-deep .navbar-dark .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
  font-weight: 700 !important;
}

.navbar {
  color: black;
}

.icon icon-s {
  height: 100%;
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.text-dark {
  color: var(--bs-body-color) !important;
}

ul {
  list-style-type: none;
  list-style: none;
}
</style>
