<template>
  <div class="wrapper">
    <div class="local-wrapper">
      <LocaleChanger />
    </div>
    <h1>{{ $t("imprint.title") }}</h1>
    <hr class="divider light my-4" />
    <h2>{{ $t("imprint.detailsSection.section1.title") }}</h2>
    <p>
      <br />Studio 177 GmbH
      <br />Zieblandstr. 22
      <br />80798 München
      <br /><br />
    Handelsregister: HRB 284198
    <br />Registergericht: Amtsgericht München

    <br /><br />Vertreten durch:
    <br />Alessa Schuhmacher

    <br /><br />Telefon: +49 221 98819760 
    <br />E-Mail: support@studio177.de
  </p>
    <p>
      Codelos.io GmbH
      <br />Lerchenauer Str. 6
      <br />80809
      <br />München
      <br />Deutschland
      
      <br/><br/>Geschäftsführer 
      <br/>Louqmane Gharnati  
      <br/>Amtsgericht München HRB 258698  
      <br/>UmstStID DE333635394  
      <br/><br/>
      Telefon: (+49) 0170 4659913
      <br />E-Mail: louq@codelos.io
    </p>

    <h3>{{ $t("imprint.detailsSection.section8.title") }}</h3>
    <p>
      {{ $t("imprint.detailsSection.section8.line1") }}
      <a
        href="https://pixabay.com/de/users/Coverr-Free-Footage-1281706/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=117"
      >
        {{ $t("imprint.detailsSection.section8.link1") }}</a
      >
      /
      <a
        href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=117"
      >
        {{ $t("imprint.detailsSection.section8.link2") }}</a
      >
    </p>
  </div>
</template>

<script>
import LocaleChanger from "./LocaleChanger.vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
export default {
  name: "Impressum",
  components: {
    LocaleChanger,
  },
};
</script>
<style scoped>
.wrapper {
  margin: 50px 5%;
  text-align: left;
}

a {
  color: blue;
}
</style>
