<template>
  <div class="wrapper">
    <LocaleChanger />
    <h1>{{ $t("privacy.title") }}</h1>
    <hr class="divider light my-4" />
    <p>
      <br />Studio 177 GmbH
      <br />Tel.: +49 221 98819760 
    <br />E-Mail: support@studio177.de
    <br/><br/>Address:
      <br />Zieblandstr. 22
      <br />80798 
      <br/> München
      <br />Deutschland
    <br />
    </p>
    <p>
      Codelos.io GmbH
      <br />Tel: (+49) 01704659913
      <br />E-Mail: louq@codelos.io
      <br/><br/>Address:
      <br/>Codelos.io GmbH
      <br />Lerchenauer Str. 6
      <br />80809
      <br />München
      <br />Deutschland
    </p>
    <p>
      {{ $t("privacy.projectTitle") }}
      <br />{{ $t("privacy.address.phone") }}<br />{{
        $t("privacy.address.email")
      }}
      <br /><br />{{ $t("privacy.address.title") }} <br />{{
        $t("privacy.projectTitle")
      }}
      <br />{{ $t("privacy.address.addressLine1") }} <br />{{
        $t("privacy.address.addressLine2")
      }}
      <br />{{ $t("privacy.address.addressLine3") }} <br />{{
        $t("privacy.address.addressLine4")
      }}
    </p>
    <h2>{{ $t("privacy.sections.section1.title") }}</h2>
    <p>
      {{ $t("privacy.sections.section1.list.title") }}
    </p>
    <ul>
      <li>
        {{ $t("privacy.sections.section1.list.line1") }}
      </li>
      <li>{{ $t("privacy.sections.section1.list.line2") }}</li>
      <li>{{ $t("privacy.sections.section1.list.line3") }}</li>
      <li>
        {{ $t("privacy.sections.section1.list.line4") }}
      </li>
      <li>
        {{ $t("privacy.sections.section1.list.line5") }}
      </li>
      <li>
        {{ $t("privacy.sections.section1.list.line6") }}
      </li>
    </ul>
    <p>
      {{ $t("privacy.sections.section1.p1") }}
    </p>
    <p>
      {{ $t("privacy.sections.section1.p2") }}
    </p>
    <p>
      {{ $t("privacy.sections.section1.p3") }}
      <a
        href="https://www.bfdi.bund.de/DE/Infothek/Addressen_Links/Addressen_links-node.html"
        target="_blank"
        rel="nofollow noopener"
        >https://www.bfdi.bund.de/DE/Infothek/Addressen_Links/Addressen_links-node.html</a
      >.
    </p>
    <p></p>
    <h2>{{ $t("privacy.sections.section2.title") }}</h2>
    <h3>{{ $t("privacy.sections.section3.title") }}</h3>
    <p>
      {{ $t("privacy.sections.section3.p1") }}
    </p>
    <p>{{ $t("privacy.sections.section3.p2") }}</p>
    <ul>
      <li>{{ $t("privacy.sections.section3.list.line1") }}</li>
      <li>{{ $t("privacy.sections.section3.list.line2") }}</li>
      <li>{{ $t("privacy.sections.section3.list.line3") }}</li>
      <li>{{ $t("privacy.sections.section3.list.line4") }}</li>
    </ul>
    <p>{{ $t("privacy.sections.section3.p3") }}</p>
    <h3>{{ $t("privacy.sections.section4.title") }}</h3>
    <p>{{ $t("privacy.sections.section4.line1") }}</p>
    <h3>{{ $t("privacy.sections.section5.title") }}</h3>
    <p>{{ $t("privacy.sections.section5.line1") }}</p>
    <h3>{{ $t("privacy.sections.section6.title") }}</h3>
    <p>{{ $t("privacy.sections.section6.line1") }}</p>
    <h3>{{ $t("privacy.sections.section7.title") }}</h3>
    <p>{{ $t("privacy.sections.section7.line1") }}</p>
    <p></p>
    <h2>{{ $t("privacy.sections.section8.title") }}</h2>
    <h3>{{ $t("privacy.sections.section9.title") }}</h3>
    <p>{{ $t("privacy.sections.section9.line1") }}</p>
    <h3>{{ $t("privacy.sections.section10.title") }}</h3>
    <p>{{ $t("privacy.sections.section10.line1") }}</p>
    <h3>{{ $t("privacy.sections.section11.title") }}</h3>
    <p>{{ $t("privacy.sections.section11.line1") }}</p>
    <h3>{{ $t("privacy.sections.section12.title") }}</h3>
    <p>{{ $t("privacy.sections.section12.line1") }}</p>
    <h3>{{ $t("privacy.sections.section13.title") }}</h3>
    <p>{{ $t("privacy.sections.section13.line1") }}</p>
    <p></p>
    <h2>{{ $t("privacy.sections.section14.title") }}</h2>
    <h3>{{ $t("privacy.sections.section15.title") }}</h3>
    <p>{{ $t("privacy.sections.section15.line1") }}</p>
    <h3>{{ $t("privacy.sections.section16.title") }}</h3>
    <p>{{ $t("privacy.sections.section16.line1") }}</p>
    <p>{{ $t("privacy.sections.section16.line2") }}</p>
    <p>{{ $t("privacy.sections.section16.line3") }}</p>
    <h3>{{ $t("privacy.sections.section17.title") }}</h3>
    <p>{{ $t("privacy.sections.section17.line1") }}</p>
    <h3>{{ $t("privacy.sections.section18.title") }}</h3>
    <p>{{ $t("privacy.sections.section18.line1") }}</p>
    <p>{{ $t("privacy.sections.section18.line2") }}</p>
    <h3>{{ $t("privacy.sections.section19.title") }}</h3>
    <p>{{ $t("privacy.sections.section19.line1") }}</p>
    <p></p>
    <h2>{{ $t("privacy.sections.section20.title") }}</h2>
    <h3>{{ $t("privacy.sections.section21.title") }}</h3>
    <p>{{ $t("privacy.sections.section21.line1") }}</p>
    <p>{{ $t("privacy.sections.section21.line2") }}</p>
    <h3>{{ $t("privacy.sections.section22.title") }}</h3>
    <p>{{ $t("privacy.sections.section22.line1") }}</p>
    <h3>{{ $t("privacy.sections.section23.title") }}</h3>
    <p>{{ $t("privacy.sections.section23.line1") }}</p>
    <h3>{{ $t("privacy.sections.section24.title") }}</h3>
    <p>{{ $t("privacy.sections.section24.line1") }}</p>
    <h3>{{ $t("privacy.sections.section25.title") }}</h3>
    <p>
      {{ $t("privacy.sections.section25.line1") }}
      <a href="https://www.privacyshield.gov/EU-US-Framework"
        >https://www.privacyshield.gov/EU-US-Framework</a
      >.
    </p>
    <h3>{{ $t("privacy.sections.section26.title") }}</h3>
    <p>{{ $t("privacy.sections.section26.line1") }}</p>
    <h3>{{ $t("privacy.sections.section27.title") }}</h3>
    <p>
      {{ $t("privacy.sections.section27.line1") }}
      <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
        {{ $t("privacy.sections.section27.aTag") }}</a
      >.
    </p>
    <p>
      {{ $t("privacy.sections.section27.line2") }}
      <a
        title="Google Analytics Opt-Out-Cookie setzen"
        onclick="gaOptout();alert('Google Analytics wurde deaktiviert');"
        href="#"
        >{{ $t("privacy.sections.section27.aTag2") }}</a
      >. {{ $t("privacy.sections.section27.line3") }}
    </p>
    <h3>{{ $t("privacy.sections.section28.title") }}</h3>
    <p>{{ $t("privacy.sections.section28.line1") }}</p>
    <p></p>
    <h2>{{ $t("privacy.sections.section29.title") }}</h2>
    <h3>{{ $t("privacy.sections.section30.title") }}</h3>
    <p>
      {{ $t("privacy.sections.section30.line1") }}
    </p>
    <p>
      {{ $t("privacy.sections.section30.line2") }}
      <a href="https://www.google.com/policies/privacy/"
        >https://www.google.com/policies/privacy/</a
      >
    </p>
    <h3>{{ $t("privacy.sections.section31.title") }}</h3>
    <p>{{ $t("privacy.sections.section31.line1") }}</p>
    <h3>{{ $t("privacy.sections.section32.title") }}</h3>
    <p>{{ $t("privacy.sections.section32.line1") }}</p>
    <h3>{{ $t("privacy.sections.section33.title") }}</h3>
    <p>{{ $t("privacy.sections.section33.line1") }}</p>
    <p>
      {{ $t("privacy.sections.section33.line2") }}
      <a href="https://developers.google.com/fonts/faq"
        >https://developers.google.com/fonts/faq</a
      >
      {{ $t("privacy.sections.section33.aTag") }}
      <a href="https://www.google.com/policies/privacy/"
        >https://www.google.com/policies/privacy/</a
      >.
    </p>
    <h3>{{ $t("privacy.sections.section34.title") }}</h3>
    <p>
      {{ $t("privacy.sections.section34.line1") }}
      <a href="https://www.privacyshield.gov/EU-US-Framework"
        >https://www.privacyshield.gov/EU-US-Framework</a
      >.
    </p>
    <h3>{{ $t("privacy.sections.section35.title") }}</h3>
    <p>{{ $t("privacy.sections.section35.line1") }}</p>
    <h3>{{ $t("privacy.sections.section36.title") }}</h3>
    <p>{{ $t("privacy.sections.section36.line1") }}</p>
    <p></p>
    <h3>{{ $t("privacy.sections.section37.title") }}</h3>
    <p>{{ $t("privacy.sections.section37.line1") }}</p>
    <p></p>
    <h3>{{ $t("privacy.sections.section38.title") }}</h3>
    <p>{{ $t("privacy.sections.section38.line1") }}</p>
    <h3>{{ $t("privacy.sections.section39.title") }}</h3>
    <p>{{ $t("privacy.sections.section39.line1") }}</p>
    <p>
      Louqmane Gharnati
      <br />Tel: (+49) 01704659913 <br />E-Mail: louq@codelos.io
    </p>
  </div>
</template>
<script>
import LocaleChanger from "./LocaleChanger.vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
export default {
  name: "Impressum",
  components: {
    LocaleChanger,
  },
};
</script>
<style scoped>
.wrapper {
  margin: 50px 5%;
  text-align: left;
}

a {
  color: blue;
}
</style>
