import Vue from "vue";
import App from "./App.vue";
import Router from "vue-router";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Impressum from "./components/Impressum.vue";
import Privacy from "./components/Privacy.vue";
import Home from "./components/Home.vue";
import About from "./components/About.vue";
import Apply from "./components/Apply.vue";
import HowItWorks from "./components/HowItWorks.vue";
import VueI18n from "vue-i18n";
import ge from "./locales/ge.json";
import en from "./locales/en.json";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(Router);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en, ge },
});

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/impressum",
    name: "impressum",
    component: Impressum,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/apply",
    name: "apply",
    component: Apply,
  },
  {
    path: "/how-it-works",
    name: "how-it-works",
    component: HowItWorks,
  },
 
 
];

const router = new Router({
  mode: "history",
  routes,
});

new Vue({
  router,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
