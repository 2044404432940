
<template>

  <div class="locale-changer" :style="cssVars">

    <b-nav-item-dropdown :text="$i18n.locale" id="locale-menu" right>

      <ul>

        <li
          @click="$i18n.locale = lang.code"
          v-for="(lang, i) in langs"
          :key="`Lang${i}`"
        >

          <b-dropdown-item> {{ lang.text }}</b-dropdown-item>

        </li>

      </ul>

    </b-nav-item-dropdown>

  </div>

</template>

<script>
export default {
  name: "locale-changer",
  data() {
    return {
      langs: [
        { code: "en", text: "English" },
        { code: "ge", text: "German" },
      ],
    };
  },
  computed: {
    cssVars() {
      return {
        "--color": this.buttonColor,
      };
    },
  },
  props: {
    buttonColor: String,
  },
  methods: {},
};
</script>

<style>
.locale-changer {
  position: absolute;
  right: 30px;
  top: 20px;
  /* color: white; */
}
li {
  list-style-type: none;
}
.nav-item .nav-link {
  color: var(--color);
  list-style-type: none;
}
</style>

